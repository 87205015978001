import React from "react";
import {
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import Link from "./Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faCalendarDays,
  faCalendarPen,
  faUsers,
  faUsersMedical,
  faBriefcaseMedical,
  faHouseMedical,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "../url.js";
import { useRoles } from "../context/AuthenticationTokenContext";
import { isGrantedAny } from "shared/src/roles.mjs";
import { anyRoleByPath } from "../router";

export const drawerOpenWidth = 236;

const openedMixin = (theme) => ({
  width: drawerOpenWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  overflowX: "hidden",
});

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerOpenWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(1),
  },
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

const menu = [
  {
    to: "/calendar",
    icon: <FontAwesomeIcon icon={faCalendarDays} />,
    text: "drawer.calendar",
  },
  {
    to: "/fcalendar",
    icon: <FontAwesomeIcon icon={faCalendarDays} />,
    text: "drawer.fcalendar",
  },
  {
    to: "/femployees",
    icon: <FontAwesomeIcon icon={faUsersMedical} />,
    text: "drawer.femployees",
  },
  {
    to: "/calendars",
    icon: <FontAwesomeIcon icon={faCalendarPen} />,
    text: "drawer.calendars",
  },
  {
    to: "/clients",
    icon: <FontAwesomeIcon icon={faUsers} />,
    text: "drawer.clients",
  },
  {
    to: "/reception",
    icon: <FontAwesomeIcon icon={faGauge} />,
    text: "drawer.reception",
  },
  {
    to: "/services",
    icon: <FontAwesomeIcon icon={faBriefcaseMedical} />,
    text: "drawer.services",
  },
  {
    to: "/locations",
    icon: <FontAwesomeIcon icon={faHouseMedical} />,
    text: "drawer.locations",
  },
];

const Drawer = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const navigate = useNavigate();
  const currentRoles = useRoles();

  return (
    <CustomDrawer open={open} variant="permanent">
      <Box
        sx={{
          textAlign: "center",
          textDecoration: "none",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        mt={1}
        onClick={() => navigate("/")}
      >
        <Box>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="10"
            viewBox="0 0 26 10"
            fill="none"
          >
            <path
              id="Vector"
              d="M4.18017 0H0.345459V3.84909H4.18017V0Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M25.6543 0H21.8196V3.84909H25.6543V0Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M9.5487 5.38879H5.71399V9.23788H9.5487V5.38879Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M14.9172 5.38879H11.0825V9.23788H14.9172V5.38879Z"
              fill="white"
            />
            <path
              id="Vector_5"
              d="M20.2859 5.38879H16.4512V9.23788H20.2859V5.38879Z"
              fill="white"
            />
          </svg>
        </Box>
        <Box
          sx={[
            {
              flexDirection: "column",
              alignItems: "center",
            },
            open
              ? {
                  display: "flex",
                }
              : {
                  display: "none",
                },
          ]}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="116"
            height="51"
            viewBox="0 0 116 51"
            fill="none"
          >
            <path
              d="M20.8098 21.0114C20.8098 23.8788 23.0526 25.0186 25.5993 25.0186C27.2662 25.0186 29.8093 24.5311 29.8093 22.3161C29.8093 19.9469 26.5365 19.6136 23.3709 18.7319C20.1517 17.9433 16.9324 16.5813 16.9324 12.5706C16.9324 8.10467 21.1138 6.01147 25.0234 6.01147C29.5375 6.01147 33.6903 7.9828 33.6903 12.9935H29.0832C28.933 10.4129 27.1124 9.71397 24.8124 9.71397C23.2958 9.71397 21.5395 10.352 21.5395 12.1727C21.5395 13.843 22.5697 14.0867 27.978 15.4523C29.584 15.8107 34.4164 16.886 34.4164 21.7677C34.4164 25.7462 31.326 28.7211 25.4169 28.7211C20.6274 28.7211 16.1169 26.3555 16.2027 21.0114H20.8098Z"
              fill="white"
            />
            <path
              d="M49.6742 28.1438H44.3177L37.2925 6.58887H42.1987L47.012 21.7439H47.0728L51.9469 6.58887H56.8495L49.6742 28.1438Z"
              fill="white"
            />
            <path
              d="M71.4652 4.55895H67.517L64.143 0.237793H67.5529L69.4983 2.70806L71.476 0.237793H74.8212L71.4652 4.55895ZM61.451 6.54308H77.6855V10.569H66.2356V15.2502H76.7558V18.9736H66.2356V24.3102H77.9403V28.3361H61.451V6.54308Z"
              fill="white"
            />
            <path
              d="M88.6115 10.5763H82.1586V6.58887H99.7982V10.5763H93.3453V28.1438H88.6115V10.5763Z"
              fill="white"
            />
            <path
              d="M2.68365 43.0923H5.6336C6.8493 43.0923 7.48342 42.5742 7.48342 41.3302C7.48342 40.0861 6.8493 39.6209 5.6336 39.6209H2.68365V43.0923ZM0 37.5205H6.60056C8.79374 37.5205 10.1846 39.0571 10.1846 40.9178C10.1846 42.3663 9.61703 43.4553 8.24718 44.005V44.0403C9.56799 44.3927 9.94287 45.6614 10.0305 46.9196C10.0795 47.6984 10.0304 49.1645 10.5455 49.8376H7.85128C7.54298 49.0587 7.5605 47.8711 7.42387 46.8878C7.23468 45.5945 6.72317 45.0235 5.38133 45.0235H2.69067V49.8376H0V37.5205Z"
              fill="white"
            />
            <path
              d="M21.1868 47.7548C23.6125 47.7548 24.523 45.6838 24.523 43.6339C24.523 41.4784 23.6125 39.4074 21.1868 39.4074C18.7611 39.4074 17.847 41.4749 17.847 43.6339C17.847 45.6838 18.7611 47.7548 21.1868 47.7548ZM21.1868 37.1356C24.973 37.1356 27.2264 39.978 27.2264 43.6339C27.2264 47.1842 24.973 50.0301 21.1868 50.0301C17.4005 50.0301 15.1471 47.1842 15.1471 43.6339C15.1471 39.978 17.4005 37.1356 21.1868 37.1356Z"
              fill="white"
            />
            <path
              d="M37.7187 49.8376H34.673L30.6777 37.5205H33.4667L36.2029 46.182H36.2381L39.0094 37.5205H41.7984L37.7187 49.8376Z"
              fill="white"
            />
            <path
              d="M46.2083 37.5205H48.8853L54.0042 45.7836H54.0393V37.5205H56.562V49.8376H53.8674L48.766 41.5921H48.7309V49.8376H46.2083V37.5205Z"
              fill="white"
            />
            <path
              d="M67.7078 36.3388H65.9968L67.6549 33.8639H70.3643L67.7078 36.3388ZM65.5911 45.0434H68.7662L67.221 40.5108H67.1857L65.5911 45.0434ZM65.8487 37.4631H68.6321L73.243 49.8376H70.4208L69.4859 47.0657H64.875L63.9083 49.8199H61.1637L65.8487 37.4631Z"
              fill="white"
            />
            <path
              d="M79.0004 39.7978H75.3521V37.5205H85.3223V39.7978H81.674V49.8376H79.0004V39.7978Z"
              fill="white"
            />
            <path
              d="M90.1156 37.5205H99.1836V39.7978H92.7798V42.4382H98.6563V44.5427H92.7798V47.5603H99.3189V49.8376H90.1156V37.5205Z"
              fill="white"
            />
            <path
              d="M104.688 37.5205H107.384V42.625L112.171 37.5205H115.534L110.747 42.3853L116 49.8376H112.616L108.927 44.2995L107.384 45.8682V49.8376H104.688V37.5205Z"
              fill="white"
            />
          </svg>
          <Box
            sx={{
              borderTop: "1px solid",
              borderTopColor: "background.default",
              marginTop: 1.5,
              paddingTop: 1,
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={[
                {
                  ml: 1,
                  fontSize: "1.4rem",
                  fontWeight: "400",
                  letterSpacing: ".48rem",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  color: "background.default",
                },
                open
                  ? {
                      display: "flex",
                    }
                  : {
                      display: "none",
                    },
              ]}
            >
              Clinis
            </Typography>
          </Box>
        </Box>
      </Box>
      <List>
        <ListItem
          sx={[
            open
              ? {
                  position: "absolute",
                  top: "-7.5rem",
                  right: "-2rem",
                  width: "auto",
                }
              : {
                  position: "static",
                  justifyContent: "center",
                },
          ]}
        >
          <IconButton
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={[
              {
                aspectRatio: "1 / 1",
                "&:hover": { backgroundColor: "primary.dark" },
              },
              open
                ? {
                    transform: "rotate(0deg)",
                  }
                : {
                    transform: "rotate(180deg)",
                  },
            ]}
          >
            {<OpenDrawerIcon />}
          </IconButton>
        </ListItem>
        {menu.map(
          (m, i) =>
            isGrantedAny(currentRoles, anyRoleByPath[m.to]) && (
              <CustomListItem key={i} isMini={!open} navigationItem={m} />
            ),
        )}
      </List>
    </CustomDrawer>
  );
};

const NavigationListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "isMini",
})(({ theme, isMini }) => ({
  justifyContent: "center",
  borderRadius: isMini ? "50%" : theme.shape.borderRadius,
  transition: isMini
    ? theme.transitions.create("border-radius", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        delay: theme.transitions.duration.enteringScreen,
      })
    : "none",
  "&.Mui-selected, &:hover": {
    backgroundColor: theme.palette.primary.main,
    "& .MuiSvgIcon-root": {
      color: (theme.vars ?? theme).palette.primary.dark,
    },
    "& .MuiTouchRipple-child": {
      backgroundColor: (theme.vars ?? theme).palette.primary.dark,
    },
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    "& .MuiTypography-root": {
      color: (theme.vars ?? theme).palette.background.dark,
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.background.dark,
    },
    "&:hover": {
      "& .MuiTypography-root": {
        color: (theme.vars ?? theme).palette.background.default,
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.background.default,
      },
    },
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,
  },
  "& .MuiSvgIcon-root": {
    color: (theme.vars ?? theme).palette.background.default,
  },
  "& .MuiListItemText-root": {
    justifyContent: isMini ? "center" : "left",
    display: isMini ? "none" : "flex",
  },
  "& .MuiListItemIcon-root": {
    marginRight: isMini ? 0 : theme.spacing(1),
  },
}));

const CustomListItem = ({ isMini, navigationItem }) => {
  const { t } = useTranslation();
  const btnProps =
    typeof navigationItem.to === "function"
      ? { onClick: navigationItem.to }
      : { component: Link, to: navigationItem.to };
  const listItemIconSize = 12;
  const location = useLocation();
  const pathname = location.pathname;
  const isSelected = pathname === navigationItem.to;

  return (
    <Tooltip title={isMini ? navigationItem.text : ""} placement="right">
      <ListItem
        sx={{
          py: isMini ? "5px" : 0,
          px: isMini ? "5px" : 1,
          mb: isMini ? 0 : 1,
          overflowX: "hidden",
        }}
      >
        <NavigationListItemButton
          selected={isSelected}
          sx={{
            height: isMini ? 32 : 36,
            justifyContent: "center",
          }}
          isMini={isMini}
          {...btnProps}
        >
          {navigationItem.icon || isMini ? (
            <ListItemIcon
              sx={{
                minWidth: listItemIconSize,
                width: listItemIconSize,
                justifyContent: "center",
                color: "background.default",
                mr: 1.2,
              }}
            >
              {navigationItem.icon ?? null}
            </ListItemIcon>
          ) : null}
          <ListItemText
            primary={t(navigationItem.text)}
            sx={{
              whiteSpace: "nowrap",
              color: "background.default",
              zIndex: 1,
            }}
          />
        </NavigationListItemButton>
      </ListItem>
    </Tooltip>
  );
};

const OpenDrawerIcon = () => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.03343 12.2432H11.5875C12.1152 12.2432 12.5469 11.8115 12.5469 11.2838C12.5469 10.7561 12.1152 10.3243 11.5875 10.3243H1.03343C0.505731 10.3243 0.0739746 10.7561 0.0739746 11.2838C0.0739746 11.8115 0.505731 12.2432 1.03343 12.2432ZM1.03343 7.44595H8.70911C9.23681 7.44595 9.66857 7.0142 9.66857 6.48649C9.66857 5.95879 9.23681 5.52703 8.70911 5.52703H1.03343C0.505731 5.52703 0.0739746 5.95879 0.0739746 6.48649C0.0739746 7.0142 0.505731 7.44595 1.03343 7.44595ZM0.0739746 1.6892C0.0739746 2.2169 0.505731 2.64866 1.03343 2.64866H11.5875C12.1152 2.64866 12.5469 2.2169 12.5469 1.6892C12.5469 1.16149 12.1152 0.729736 11.5875 0.729736H1.03343C0.505731 0.729736 0.0739746 1.16149 0.0739746 1.6892ZM16.6726 9.24974L13.9094 6.48649L16.6726 3.72325C17.0468 3.34906 17.0468 2.7446 16.6726 2.37041C16.2984 1.99622 15.694 1.99622 15.3198 2.37041L11.8753 5.81487C11.5011 6.18906 11.5011 6.79352 11.8753 7.16771L15.3198 10.6122C15.694 10.9864 16.2984 10.9864 16.6726 10.6122C17.0372 10.238 17.0468 9.62393 16.6726 9.24974Z"
      fill="#F4F7FE"
    />
  </svg>
);

export default Drawer;
