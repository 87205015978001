import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as f from "../utils/formatter";
import { DEBOUNCE_INPUT } from "../constants";
import { debounce } from "shared/src/util.mjs";
import { useLatest } from "../hooks";
import { createClientFilter } from "../client";
import * as api from "../services/api";
import { createUrlString } from "../url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function ClientAutocomplete({ search, options, option, onChange, loading }) {
  const { t } = useTranslation();

  return (
    <>
      <input type="hidden" name="client_id" value={option?.id ?? ""} />
      <Autocomplete
        forcePopupIcon={false}
        sx={{ minWidth: "400px" }}
        filterOptions={(x) => x}
        autoComplete
        loading={loading}
        options={options}
        getOptionLabel={(opt) => f.fullName(opt)}
        getOptionKey={(opt) => opt.id}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        renderInput={(params) => {
          const { InputProps, ...props } = params;
          return (
            <TextField
              placeholder={"Hledat"}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "background.darker",
                  "& fieldset": {
                    borderColor: "divider",
                  },
                  "& input::placeholder": {
                    opacity: 0.9,
                    color: "var(--mui-palette-_components-heading-color)",
                  },
                },
              }}
              slotProps={{
                input: {
                  ...InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputAdornment>
                  ),
                },
              }}
              {...props}
            />
          );
        }}
        renderOption={(props, option) => {
          const { key, ...otherProps } = props;

          return (
            <li key={key} {...otherProps}>
              <Box>
                <strong>{f.fullName(option)}</strong>
                <br />
                {t("client.birth_number")}: {option.birth_number}{" "}
                {t("client.phone")}: {option.phone}
                <br />
                {option.street} {option.city}
                <br />
                {option.post_code}
              </Box>
            </li>
          );
        }}
        onInputChange={(_, term) => {
          search(term);
        }}
        onChange={(_, value) => {
          onChange(value == null ? null : value);
        }}
      />
    </>
  );
}

async function doClientSearch(signal, term, location_id) {
  let filter = createClientFilter(term);
  if (filter == null) {
    return [];
  }

  if (location_id != null) {
    filter = { and: [filter, { location_id: { eq: location_id } }] };
  }

  const res = await api.clientSearch(
    {
      filter: filter,
      page: { limit: 10, offset: 0 },
    },
    { signal },
  );

  return res.data.clients.data;
}

function useClients(term, location_id) {
  const [state, setState] = React.useState({ result: [], loading: false });
  useLatest(
    async (signal) => {
      setState((state) => ({ ...state, loading: true }));
      try {
        const result = await doClientSearch(signal, term, location_id);
        setState((state) => ({ ...state, result }));
      } finally {
        if (!signal.aborted) {
          setState((state) => ({ ...state, loading: false }));
        }
      }
    },
    [term, location_id],
  );

  return state;
}

function useClientOptions(location_id = null, option = null) {
  const [term, setTerm] = React.useState("");
  const { result, loading } = useClients(term, location_id);
  const options = React.useMemo(() => {
    if (option == null) {
      return result;
    }

    if (result.find((opt) => opt.id === option.id)) {
      return result;
    }

    return [option, ...result];
  }, [option, result]);

  return [
    options,
    React.useCallback(debounce(setTerm, DEBOUNCE_INPUT), []),
    loading,
  ];
}

export default function ClientSearch() {
  const [option] = React.useState(null);
  const [options, clientSearch, clientOptionsLoading] = useClientOptions(
    null,
    option,
  );

  return (
    <ClientAutocomplete
      search={clientSearch}
      loading={clientOptionsLoading}
      options={options}
      option={option}
      onChange={(option) => {
        if (option == null) {
          return;
        }

        window.open(
          createUrlString(
            location.origin,
            location.search,
            `/clients/${option.id}`,
          ),
          "_blank",
        );
      }}
    />
  );
}
