import { alpha, IconButton, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Badge from "@mui/material/Badge";
import NotificationMenu from "./NotificationMenu";
import React, { useState, useEffect } from "react";
import { useNotifications } from "../../context/NotificationContext";
import { useNavigate } from "../../url";

/**
 * NotificationsBell component renders a bell icon with a badge indicating the count of unread notifications.
 * It also handles the opening and closing of the notifications menu.
 *
 * @component
 * @example
 * return (
 *   <NotificationsBell />
 * )
 */
export default function NotificationsBell() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { notifications, fetchNotifications, unreadCount } = useNotifications();

  const theme = useTheme();

  useEffect(() => {
    fetchNotifications();
  }, []);

  /**
   * Handles click event to open the notifications menu.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event - The button click event.
   * @returns {void}
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // fetchNotifications();
  };

  /**
   * Handles closing of the notifications menu.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Badge
        badgeContent={unreadCount}
        color="error"
        sx={{
          "& .MuiBadge-badge": {
            right: -9,
            top: -3,
            transform: "scale(0.9)",
          },
        }}
      >
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            width: 35,
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
          }}
          size="medium"
        >
          <FontAwesomeIcon
            icon={faBell}
            color={theme.palette.primary.main}
            size="xs"
            fixedWidth
          />
        </IconButton>
      </Badge>
      <NotificationMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        notifications={notifications}
        navigate={navigate}
      />
    </>
  );
}
