import React from "react";
import { Snackbar as MuiSnackbar, Alert, AlertTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const Snackbar = ({ open, handleClose, severity, title, children }) => {
  console.log(severity); // TODO remove, it is only to use the variable and avoid the warning
  return (
    <MuiSnackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={100000000} // TODO, change to 10000
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          backgroundColor: "white",
          boxShadow: "0 0.2rem 0.3rem rgba(0,0,0,0.5)",
          borderRadius: "0",
        }}
      >
        <div style={{ padding: "0.5rem 0", backgroundColor: "white" }}>
          <div
            style={{
              backgroundColor: "green",
              width: "0.7rem",
              height: "100%",
              borderRadius: "0 1rem 1rem 0",
            }}
          />
        </div>
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
          icon={
            <div
              style={{
                backgroundColor: "lightgreen",
                padding: "0.75rem",
                borderRadius: "100%",
                aspectRatio: "1 / 1",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <FontAwesomeIcon icon={faExclamationCircle} color="green" />
            </div>
          }
          style={{
            backgroundColor: "inherit",
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "1rem",
          }}
        >
          <AlertTitle
            color="green"
            sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
          >
            {title}
          </AlertTitle>
          <span style={{ color: "black" }}>{children}</span>
        </Alert>
      </div>
    </MuiSnackbar>
  );
};

export default Snackbar;
