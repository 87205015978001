export const role = {
  Back_Office: "Back_Office",
  Dental_Assistant: "Dental_Assistant",
  Doctor: "Doctor",
  Floor_Manager: "Floor_Manager",
  Front_Office: "Front_Office",
  Treatment_Coordinator: "Treatment_Coordinator",
};

/**
 * @param {object} roles
 *
 * @returns {string[]}
 */
export function grantedLocations(roles) {
  return Object.keys(roles ?? {});
}

/**
 * @param {Set<string>} currentRoles
 * @param {Set<string>} [requiredRoles]
 */
export function isGrantedAny(currentRoles, requiredRoles) {
  if ((requiredRoles?.size ?? 0) === 0) {
    return true;
  }

  return currentRoles.intersection(requiredRoles).size > 0;
}
