import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWizardContext } from "./AppointmentWizardContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCheckCircle,
  faBellConcierge,
  faCalendarLinesPen,
  faBroom,
} from "@fortawesome/pro-solid-svg-icons";

const AppointmentWizard = () => {
  const { t } = useTranslation();
  const { activeStep } = useWizardContext();

  const steps = [
    { label: t("wizard.steps.preparation"), icon: faCalendarCheck },
    { label: t("wizard.steps.completion"), icon: faCheckCircle },
    { label: t("wizard.steps.receptionNotes"), icon: faBellConcierge },
    { label: t("wizard.steps.procedureNotes"), icon: faCalendarLinesPen },
    { label: t("wizard.steps.chairCleaning"), icon: faBroom },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        position: "fixed",
        bottom: "20px",
        width: "100%",
        maxWidth: "60%",
        padding: "15px 15px",
        backgroundColor: "#f8f8f8",
        borderRadius: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1000,
      }}
    >
      {steps.map((step, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: activeStep === index ? "primary.main" : "text.secondary",
            transition: "color 0.3s",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 36,
              height: 36,
              borderRadius: "50%",
              backgroundColor:
                activeStep === index ? "primary.light" : "transparent",
              color: activeStep === index ? "primary.main" : "inherit",
              transition: "background-color 0.3s, color 0.3s",
            }}
          >
            <FontAwesomeIcon icon={step.icon} size="xl" />
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontWeight: activeStep === index ? "bold" : "normal",
              marginTop: "4px",
              fontSize: "0.75rem",
            }}
          >
            {step.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default AppointmentWizard;
