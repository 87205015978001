import React from "react";

const ServiceTag = ({ service }) => {
  return (
    <div
      style={{
        borderRadius: "4px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "var(--mui-palette-divider)",
        padding: "calc(var(--mui-spacing) / 4) calc(var(--mui-spacing) / 2)",
        display: "flex",
        alignItems: "center",
        flex: "0 1 50%",
        maxWidth: "100%",
        width: "fit-content",
        fontSize: "0.9rem",
        backgroundColor: "var(--mui-palette-background-default)",
      }}
    >
      <div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          backgroundColor: service?.color,
          minWidth: "1em",
          width: "1em",
          aspectRatio: "1 / 1",
          marginRight: "calc(var(--mui-spacing) / 2)",
        }}
      />
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          color: "var(--mui-palette-_components-heading-color)",
        }}
      >
        {service?.name}
      </span>
    </div>
  );
};
export default ServiceTag;
