import React from "react";
import { Avatar, Box, Typography, Tooltip, useTheme } from "@mui/material";
import { faUser, faCake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ClientAvatar({ client, t, isBirthdayToday = true }) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Avatar
        sx={{
          width: 56,
          height: 56,
          mr: 2,
          backgroundColor: "grey.200",
        }}
      >
        <FontAwesomeIcon
          icon={faUser}
          fixedWidth
          color={theme.palette.grey[500]}
        />
      </Avatar>
      <Box>
        <Typography variant="h4" component="h1">
          {client
            ? `${client.first_name} ${client.last_name}`
            : t("client.notFound")}
        </Typography>
        {isBirthdayToday && (
          <Tooltip title={t("client.happyBirthday")} arrow>
            <FontAwesomeIcon
              icon={faCake}
              fixedWidth
              color={theme.palette.primary.main}
              size="xl"
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

export default ClientAvatar;
