import { DOUBLE_CLICK_DELAY } from "../constants";
import { status } from "shared/src/appointment.mjs";

/**
 * @typedef Appointment
 * @property {string} start
 * @property {string} end
 * @property {number} status_id
 * @property {string} calendar_id
 *
 * @typedef Event
 * @property {Date} start
 * @property {Date} end
 * @property {string} backgroundColor
 * @property {Appointment} extendedProps
 */

export const colors = {
  "bckgr gray": "#F4F7FE",
  "bckgr darker": "#E6E9F9",
  "dark green": "#244757",
  "Heading color": "#022A3A",
  "Web background": "#EEF8F8",
  "Light blue": "#C3E4E2",
  "jeste nedorazil": "#FFFFFF",
  "Prvni konzultace": "#B2C6DE",
  Rentgen: "#104C9D",
  red: "#FF4757",
  "Text / Main": "#2B3674",
};

const inactiveStatuses = new Set([status.BOOKED, status.CONFIRMED]);

/**
 * @param {{status_id: number, service: {color: string}}} appointment
 *
 * @returns {string}
 */
export function appointmentColor(appointment) {
  return appointment.service?.color;
}

/**
 * @param {{status_id: number, service: {color: string}}} appointment
 *
 * @returns {string}
 */
export function appointmentBackground(appointment) {
  if (inactiveStatuses.has(appointment.status_id)) {
    return colors["jeste nedorazil"];
  }

  return `color-mix(in srgb, ${appointmentColor(appointment)} 15%, transparent)`;
}

/**
 * @param {Event} event
 *
 * @returns {string}
 */
export function eventStyle(event) {
  return `border-color: ${appointmentColor(event.extendedProps)}; color: ${colors["Heading color"]}`;
}

export function createClickHandler({ onClick, onDoubleClick }) {
  let timeoutId = null;
  const clear = () => {
    clearTimeout(timeoutId);
    timeoutId = null;
  };

  return function (...args) {
    if (timeoutId == null) {
      timeoutId = setTimeout(() => {
        clear();
        onClick(...args);
      }, DOUBLE_CLICK_DELAY);
      return;
    }

    clear();
    onDoubleClick(...args);
  };
}
