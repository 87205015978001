import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  Stack,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import TranslationSwitcher from "../components/TranslationSwitcher";
import { handleLoginWithAzure } from "./../auth";
import { clearIntendedUrl, getIntendedUrl } from "../utils/util";
import { useAuthenticationToken } from "../context/AuthenticationTokenContext";
import { useNavigate } from "../url";
import { useAuth } from "../context/authProvider";

const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  width: "100%",
  minWidth: "240px",
  height: "48px",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#85c8ff",
    height: "48px",
  },
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  boxSizing: "border-box",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  width: "100%",
  maxWidth: "400px",
  margin: "0 auto",
}));

const MicrosoftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="24px"
    height="24px"
    style={{ flexShrink: 0, marginRight: "8px" }}
    {...props}
  >
    <path fill="#f1511b" d="M22 22H3V3h19v19z" />
    <path fill="#80cc28" d="M45 22H26V3h19v19z" />
    <path fill="#00a1f1" d="M22 45H3V26h19v19z" />
    <path fill="#fbbc09" d="M45 45H26V26h19v19z" />
  </svg>
);

const LoginPage = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { stayLoggedIn, setStayLoggedIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAuthToken] = useAuthenticationToken();
  const [authToken] = useAuthenticationToken();

  useEffect(() => {
    const intendedUrl = getIntendedUrl();
    if (authToken != null && intendedUrl) {
      navigate(intendedUrl, { replace: true });
      clearIntendedUrl();
    }
  }, [navigate, authToken]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const loginResponse = await instance.loginPopup({
        scopes: [
          "openid",
          "profile",
          "email",
          "Directory.Read.All",
          "GroupMember.Read.All",
          "User.Read",
        ],
        prompt: "select_account",
      });

      instance.setActiveAccount(loginResponse.account);

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          scopes: [
            "openid",
            "profile",
            "email",
            "Directory.Read.All",
            "GroupMember.Read.All",
            "User.Read",
          ],
          account: loginResponse.account,
          prompt: "select_account",
        });
      } catch (error) {
        console.warn(
          "Silent token acquisition failed, using popup instead:",
          error,
        );
        tokenResponse = await instance.acquireTokenPopup({
          scopes: [
            "openid",
            "profile",
            "email",
            "Directory.Read.All",
            "GroupMember.Read.All",
            "User.Read",
          ],
          account: loginResponse.account,
          prompt: "select_account",
        });
      }

      const backendToken = await handleLoginWithAzure(
        tokenResponse,
        stayLoggedIn,
      );
      if (backendToken) setAuthToken(backendToken);

      const intendedUrl = getIntendedUrl();
      navigate(intendedUrl || "/", { replace: true });
    } catch (error) {
      console.error("LoginPage - Login failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#ececec"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#ececec"
      px={2}
    >
      <StyledPaper elevation={3}>
        <Typography variant="h1" gutterBottom>
          {t("login.title")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("login.description")}
        </Typography>
        <Stack spacing={2} width="100%">
          <LoginButton
            variant="contained"
            disableElevation={false}
            onClick={handleLogin}
            disabled={isLoading}
          >
            <MicrosoftIcon />
            {t("login.loginButton")}
          </LoginButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={stayLoggedIn}
                onChange={(e) => setStayLoggedIn(e.target.checked)}
                disabled={isLoading}
              />
            }
            label={t("login.stayLoggedIn")}
          />
        </Stack>
      </StyledPaper>
      <Box mt={4}>
        <TranslationSwitcher />
      </Box>
    </Box>
  );
};

export default LoginPage;
